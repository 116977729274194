.css-1fdsijx-ValueContainer {
   
    padding: 0px;
    /* background-color: blue; */
}
.css-qbdosj-Input {
    margin: 0px; 
    padding-bottom: 0px; 
     padding-top: 0px;
}

#selectSize{
    padding: 10;
}

#selectSizeForCanvas{
    padding: 0;
}